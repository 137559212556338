import axios from 'axios';
import { ENDPOINTS } from './constants';


export const getUserToken = async (credentials) => {
    const csrfToken = getCookie('csrftoken');
    console.log("csrfToken:", csrfToken)
    try {
        const response = await axios.post(ENDPOINTS.TOKEN, credentials,{
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken
            },
            withCredentials: true,
        })
        console.log("response:", response)
        if (!!response) {
            return response;
        } else {
            throw new Error('Login failed');
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};

const getCookie = (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
};
