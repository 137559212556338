import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

const isAuthenticated = () => {
    return !!getCookie("csrftoken");
};


const ProtectedRoute = ({ element }) => {
    const location = useLocation();
    // console.log(111, !isAuthenticated())
    if (!isAuthenticated()) {
        return <Navigate to="/login" state={{ from: location }} />;
    }
    return element;
};

export default ProtectedRoute;
