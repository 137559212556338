import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

// Function to set the Authorization token
export const setToken = (token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

// Function to remove the Authorization token
export const deleteToken = () => {
    axios.defaults.headers.common['Authorization'] = '';
};
