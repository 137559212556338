import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/LoginPage/LoginPage";
import TaskList from "./pages/TaskPage/TaskListPage";
import TaskDetailPage from "./pages/TaskPage/TaskDetailPage";
import UserDetailPage from "./pages/UserPage/UserDetailPage";
import Layout from "./components/Layout";
import TaskCreatePage from "./pages/TaskPage/TaskCreatePage";
import ForgotPassword from "./pages/LoginPage/ForgotPasswordPage";
import LogoutPage from "./pages/LoginPage/LogoutPage";
import ProtectedRoute from "./ProtectedRoute";
import ResetPasswordPage from "./pages/LoginPage/ResetPasswordPage";

const App = () => {
    return (
        <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordPage />} />

                    <Route element={<Layout />}>
                        <Route path="/tasklist" element={<ProtectedRoute element={<TaskList />} />} />
                        <Route path="/task/:taskId" element={<ProtectedRoute element={<TaskDetailPage />} />} />
                        <Route path="/task/create" element={<ProtectedRoute element={<TaskCreatePage />} />} />
                        <Route path="/userdetail" element={<ProtectedRoute element={<UserDetailPage />} />} />
                        <Route path="/logout/" element={<LogoutPage />} />
                    </Route>
                </Routes>
        </Router>
    );
};

export default App;
