import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './TaskDetailPage.module.css';
import axiosInstance from "../../axiosConfig";
import { TASK_STATUSES } from "../../api";
import { fetchDestinations } from '../../api';
import AttachmentForm from '../../components/forms/TaskForm/AttachmentsForm';


const TaskDetailPage = () => {
    const { taskId } = useParams();
    const [task, setTask] = useState(null);
    const [changes, setChanges] = useState({});
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [showComments, setShowComments] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);
    const [destinations, setDestinations] = useState([]);
    const [textVersions, setTextVersions] = useState([]);
    const [selectedTextVersion, setSelectedTextVersion] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isCommenting, setIsCommenting] = useState(false);
    const [userNames, setUserNames] = useState([]);
    const [canEdit, setCanEdit] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
    const [voteCounts, setVoteCounts] = useState({ for: 0, against: 0 });

    const getDestinations = async () => {
        const response = await fetchDestinations();
        setDestinations(response);
    };

    const fetchTask = async () => {
        try {
            const response = await axiosInstance.get(`/task/${taskId}/`);
            localStorage.setItem("userId", response.user);
            setTask(response);

        } catch (error) {
            console.error("Error getting task details:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchComments = async () => {
        try {
            const response = await axiosInstance.get(`comment/comments/${taskId}/`);
            setComments(response);
            renderComments(response);
        } catch (error) {
            console.error("Error fetching comments:", error);
        }
    };

    async function renderComments(comments) {
        const commentsContainer = document.getElementById('comments-container');
        commentsContainer.innerHTML = '';

        if (comments.length === 0) {
            const noCommentsMessage = document.createElement('div');
            noCommentsMessage.classList.add(styles.noCommentsMessage);  // Using styles from the CSS module
            noCommentsMessage.innerHTML = '<p>No comments</p>';
            commentsContainer.appendChild(noCommentsMessage);
        } else {
            for (const comment of comments) {
                const commentElement = document.createElement('div');
                commentElement.classList.add("comment");

                const user = await fetchUserById(comment.user);
                const userName = user.first_name && user.last_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.username;

                const userInfo = document.createElement('div');
                userInfo.classList.add("comment-user-info");
                userInfo.innerHTML = `
                <span class="comment-user-name"><strong>${userName}</strong></span>
                <span class="comment-timestamp">${new Date(comment.created_at).toLocaleString()}</span>
            `;
                const commentText = document.createElement('div');
                commentText.classList.add("comment-text");
                commentText.innerHTML = `<p>${comment.text}</p>`;

                commentElement.appendChild(userInfo);
                commentElement.appendChild(commentText);
                commentsContainer.appendChild(commentElement);
            }
        }
    }

    const countVotes = (votes) => {
        let forVotes = 0;
        let againstVotes = 0;

        votes.forEach((vote) => {
            if (vote.vote_type) {
                forVotes++;
            } else {
                againstVotes++;
            }
        });
        return { for: forVotes, against: againstVotes };
    };

    const handleVote = async (voteType) => {
        try {
            await axiosInstance.post(`/vote/${taskId}/votes/`, { vote_type: voteType });

            setIsUpdated((prev) => !prev);
            setIsModalOpen(false);

            setShowModal(true);
            setTimeout(() => { setShowModal(false) }, 1600);
        } catch (error) {
            console.error("Error voting:", error.response?.data || error.message);
        }
    };

    const fetchVoteCounts = async () => {
        try {
            const response = await axiosInstance.get(`vote/votes/${taskId}/`);
            const voteCounts = countVotes(response);
            setVoteCounts(voteCounts);
        } catch (error) {
            console.error("Error fetching vote counts:", error);
        }
    };

    const fetchUser = async () => {
        try {
            const response = await axiosInstance.get(`/user/current`);
            if (!!response) { return response.pk }

        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const openVoteModal = () => {
        setIsModalOpen(true);
    };

    const closeVoteModal = () => {
        setIsModalOpen(false);
    };

    const openInfoModal = () => {
        setIsModalInfoOpen(true);
    };

    const closeInfoModal = () => {
        setIsModalInfoOpen(false);
    };

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post(`/comment/`,
                { task: taskId, text: newComment, user: await fetchUser() });
            setComments((prev) => [...prev, response]);
            setNewComment("");
            setIsCommenting(false);
            setShowModal(true);
            setTimeout(() => { setShowModal(false) }, 1600);
        } catch (error) {
            console.error("Error submitting comment:", error);
        }
    };

    const fetchTextVersions = async () => {
        try {
            const response = await axiosInstance.get(`/text_version/tasks/${taskId}/versions/`);
            const versions = response;
            setTextVersions(response);

            if (versions.length > 0) {
                const latestVersion = versions[versions.length - 1];
                setSelectedTextVersion(latestVersion.id);
                setTask((prevTask) => ({ ...prevTask, text: latestVersion.text }));
            }
        } catch (error) {
            console.error("Error fetching text versions:", error.response?.data || error.message);
        }
    };

    const handleTextVersionChange = (e) => {
        const selectedId = e.target.value;
        const selectedVersion = textVersions.find((version) => version.id === parseInt(selectedId, 10));
        if (selectedVersion) {
            setTask((prevTask) => ({ ...prevTask, text: selectedVersion.text }));
            setSelectedTextVersion(selectedId);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTask((prevTask) => ({ ...prevTask, [name]: value }));

        setChanges((prevChanges) => ({ ...prevChanges, [name]: value }));
    };

    const handleSubmit = async () => {
        const userIdRaw = localStorage.getItem('userId');
        const userIdArray = userIdRaw.split(',').map(id => parseInt(id.trim(), 10));

        const payload = {
            ...changes,
            user: userIdArray,
            destination: changes.destination || task.destination,
        };
        try {
            if (Object.keys(changes).length === 0) {
                console.log("No changes to update.");
                return;
            }
            await axiosInstance.patch(`/task/${taskId}/`, payload);
            await fetchTask();
            setChanges({});
            setIsUpdated((prev) => !prev);

            setShowModal(true);
            setTimeout(() => { setShowModal(false) }, 1600);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error("You cannot edit this task");
                setCanEdit(false);
            } else {
                console.error("Unexpected error occurred:", error);
            }
        }
    };

    const toggleComments = () => {
        const commentsContainer = document.getElementById('comments-container');

        if (!showComments) { fetchComments();
        } else { commentsContainer.innerHTML = '' }

        setShowComments(!showComments);
    };

    const toggleCommentInput = () => {
        setIsCommenting((prev) => !prev);
    };

    const fetchUsers = async (userIds) => {
        try {
            const userPromises = userIds.map(async (userId) => {
                const user = await fetchUserById(userId);
                return user.first_name && user.last_name
                    ? `${user.first_name} ${user.last_name}`
                    : user.email;
            });
            const users = await Promise.all(userPromises);
            setUserNames(users);
        } catch (error) {
            console.error("Error fetching user details:", error);
            return [];
        }
    };

    const fetchUserById = async (userId) => {
        try {
            const response = await axiosInstance.get(`/user/${userId}/`);
            if (!!response) {
                return response
            } else {
                console.error(`Failed to get user with ID: ${userId}`);
                return `User ${userId}`;
            }
        } catch (error) {
            console.error("Error getting user:", error);
            return `User ${userId}`;
        }
    };

    useEffect(() => {
        fetchTask();
        getDestinations();
        fetchTextVersions();
        fetchVoteCounts();
    }, [taskId, isUpdated, showComments]);

    useEffect(() => {
        if (task?.user?.length) { fetchUsers(task.user) }
    }, [task]);

    if (!!loading) { return <div className={styles.loading}>Loading task...</div> }

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.cardBody}>
                    <h3 className={styles.taskTitle}>Task Details</h3>

                    <div className={styles.taskInfo}>
                        {!canEdit && <div className={styles.error}>You don't have permission to edit this task</div>}
                        <div className={styles.inlineFields}>
                            <div className={styles.field}>
                                <label htmlFor="status" className={styles.label}>Status:</label>
                                <select
                                    id="status"
                                    name="status"
                                    value={task?.status || ""}
                                    onChange={handleInputChange}
                                    className={styles.inputField}>
                                    {TASK_STATUSES.map((status) => (
                                        <option key={status} value={status}>
                                            {status}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="deadline" className={styles.label}>Deadline:</label>
                                <input
                                    id="deadline"
                                    type="date"
                                    name="deadline"
                                    value={task?.deadline || "SOFT"}
                                    onChange={handleInputChange}
                                    className={styles.inputField}/>
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="deadline_type" className={styles.label}>Deadline Type:</label>
                                <select
                                    id="deadline_type"
                                    name="deadline_type"
                                    value={task?.deadline_type || ""}
                                    onChange={handleInputChange}
                                    className={styles.inputField}>
                                    <option value="SOFT">Soft</option>
                                    <option value="HARD">Hard</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="title" className={styles.label}>Title:</label>
                            <input
                                id="title"
                                type="text"
                                name="title"
                                value={task?.title || ""}
                                onChange={handleInputChange}
                                className={styles.inputField}/>
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="description" className={styles.label}>Description:</label>
                            <textarea
                                id="description"
                                name="description"
                                value={task?.description || ""}
                                onChange={handleInputChange}
                                className={styles.textArea}/>
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="type" className={styles.label}>Type:</label>
                            <select
                                id="type"
                                name="type"
                                value={task?.type || ""}
                                onChange={handleInputChange}
                                className={styles.inputField}>
                                <option value={1}>Inner</option>
                                <option value={2}>Outer</option>
                            </select>
                        </div>
                        <div className={styles.inlineFields}>
                            <div className={styles.field}>
                                <label htmlFor="destination" className={styles.label}>Destination:</label>
                                <select
                                    id="destination"
                                    name="destination"
                                    value={task?.destination || []}
                                    onChange={(e) => {
                                        const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                                        setTask((prevTask) => ({...prevTask, destination: selectedOptions}));
                                        setChanges((prevChanges) => ({...prevChanges, destination: selectedOptions}));
                                    }}
                                    multiple
                                    className={styles.inputField}>
                                    {destinations.map((destination) => (
                                        <option key={destination.id} value={destination.id}>
                                            {destination.name}
                                        </option>
                                    ))}
                                </select>
                                <div className={styles.tagsContainer}>
                                    {task?.destination?.map((id) => {
                                        const destination = destinations.find(d => d.id === id);
                                        return (
                                            destination && (
                                                <div key={id} className={styles.tag}>
                                                    {destination.name}
                                                    <button
                                                        onClick={() => {
                                                            const updatedDestinations = task.destination.filter(destId => destId !== id);
                                                            setTask((prevTask) => ({
                                                                ...prevTask,
                                                                destination: updatedDestinations
                                                            }));
                                                            setChanges((prevChanges) => ({
                                                                ...prevChanges,
                                                                destination: updatedDestinations
                                                            }));
                                                        }}
                                                        className={styles.removeTagButton}>
                                                        &times;
                                                    </button>
                                                </div>
                                            )
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="reference" className={styles.label}>Reference:</label>
                                <textarea
                                    id="reference"
                                    name="reference"
                                    value={task?.reference || ""}
                                    onChange={handleInputChange}
                                    className={styles.inputField}/>
                            </div>
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="structure" className={styles.label}>Structure:</label>
                            <textarea
                                id="structure"
                                name="structure"
                                value={task?.structure || ""}
                                onChange={handleInputChange}
                                className={styles.textArea}/>
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="textVersion" className={styles.label}>Text Versions:</label>
                            <select
                                id="textVersion"
                                value={selectedTextVersion}
                                onChange={handleTextVersionChange}
                                className={styles.inputField}>
                                {textVersions.map((version) => (
                                    <option key={version.id} value={version.id}>
                                        Version {version.number_version}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="text" className={styles.label}>Text:</label>
                            <textarea
                                id="text"
                                name="text"
                                value={task?.text || ""}
                                onChange={handleInputChange}
                                className={styles.textArea}/>
                        </div>
                        <div className={styles.field}>
                            <AttachmentForm taskId={taskId}/>
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="created_at" className={styles.label}>Created at:</label>
                            <input
                                id="created_at"
                                type="text"
                                name="created_at"
                                value={task?.created_at ? new Date(task.created_at).toLocaleString() : ""}
                                readOnly
                                className={`${styles.inputField} ${styles.readOnlyField}`}/>
                        </div>
                        <div className={styles.field}>
                            <label htmlFor="users" className={styles.label}>Responsible users:</label>
                            <input
                                id="users"
                                type="text"
                                name="users"
                                value={userNames.join(", ") || "No users assigned"}
                                readOnly
                                className={`${styles.inputField} ${styles.readOnlyField}`}/>
                        </div>
                        <div>
                            <div className={styles.field}>
                                <h3 className={styles.label}>Vote Count:</h3>
                                <div className={styles.inputFieldWrapper}>
                                    <div className={styles.voteCount}>
                                        <span className={styles.voteLabel}>For: </span>
                                        <span className={styles.voteCountNumber}>{voteCounts.for}</span>
                                    </div>
                                    <div className={styles.voteCount}>
                                        <span className={styles.voteLabel}>Against: </span>
                                        <span className={styles.voteCountNumber}>{voteCounts.against}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="#"
                           onClick={(e) => {
                               e.preventDefault();
                               openInfoModal();
                           }}
                           className={styles.link}>
                            Click here for more info
                        </a>
                        <div className={styles.actionsSection}>
                            <button onClick={handleSubmit} className={styles.submitButton}>
                                Save
                            </button>
                            <button onClick={toggleCommentInput} className={styles.submitButton}>
                                {isCommenting ? "Cancel" : "Comment task"}
                            </button>
                            <button onClick={toggleComments} className={styles.submitButton}>
                                {showComments ? "Hide Comments" : "Get Comments"}
                            </button>
                            {task.status === "ON REVIEW" && (
                                <button
                                    onClick={openVoteModal}
                                    className={styles.submitButton}>
                                    Vote
                                </button>
                            )}
                            {isModalOpen && (
                                <div className={styles.modalOverlay} onClick={closeVoteModal}>
                                    <div
                                        className={styles.voteModal}
                                        onClick={(e) => e.stopPropagation()}>
                                        <h3 className={styles.voteModalTitle}>Select Your Vote:</h3>
                                        <div className={styles.voteButtons}>
                                            <button
                                                onClick={() => handleVote(true)}
                                                className={styles.submitButton}>
                                                Vote For
                                            </button>
                                            <button
                                                onClick={() => handleVote(false)}
                                                className={styles.submitButton}>
                                                Vote Against
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isModalInfoOpen && (
                                <div className={styles.modalOverlay} onClick={closeInfoModal}>
                                        <div className={styles.modal}>
                                            <div className={styles.voteModal}>
                                                <p>✅ How to vote? <br/>
                                                    - When the task in status ON REVIEW, you can see the button
                                                    Vote <br/><br/>
                                                    ✅ How are votes counted? <br/>
                                                    - When there are at least 3 votes, it counts automatically: <br/>
                                                    - If there are 2 or more "For" votes, the status is set to
                                                    APPROVED <br/>
                                                    - If there are 2 or more "Against" votes, the status is set to NOT
                                                    APPROVED
                                                </p>

                                            </div>
                                        </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {isCommenting && (
                        <form onSubmit={handleCommentSubmit} className={styles.commentForm}>
                    <textarea
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="Write your comment here..."
                        className={styles.commentInput}
                    />
                            <button type="submit" className={styles.saveCommentButton}>Save</button>
                        </form>
                    )}
                    <div id="comments-container" className={styles.commentsSection}></div>
                    {showModal && (
                        <div className={styles.modal}>
                            <div className={styles.modalContent}>
                        <span className={styles.closeButton} onClick={() => setShowModal(false)}>
                        </span> <h3>Saved successfully</h3>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TaskDetailPage;
