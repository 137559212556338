import React, {useEffect, useState} from "react";
import styles from "./ForgotPasswordPage.module.css";
import { toast } from "react-toastify";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../axiosConfig";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!!email) {
            setIsLoading(true);
            try {
                const response = await axiosInstance.post('/password/reset', { email })
                toast.success("Password reset email sent");
                navigate("/login");
            } catch (error) {
                console.error("Password reset failed:", error);
                toast.error("Failed to send password reset email. Please try again");
            }
            setIsLoading(false);
        } else {
            toast.error("Please enter a valid email");
        }
    };

    return (
        <div className={styles.forgotPasswordContainer}>
            <div className={styles.forgotPasswordBox}>
                <h2 className={styles.title}>Forgot Your Password?</h2>
                <p className={styles.subtitle}>Write your email to get a password reset link</p>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <input
                        type="email"
                        placeholder="Enter your email..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={styles.input}
                    />
                    <button type="submit" className={styles.primaryButton} disabled={isLoading}>
                        {isLoading ? "Sending..." : "Reset Password"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
