import axiosInstance from "../axiosConfig";

export const fetchDestinations = async () => {
    try {
        const response = await axiosInstance.get(`/destination/destinations/`);
        if (!!response) { return response }
    } catch (error) {
        console.error("Error getting destinations:", error);
    }
};
