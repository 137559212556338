import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosConfig";
import styles from "./ResetPasswordPage.module.css";

const ResetPasswordPage = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    const { uid, token } = useParams();

    const handlePasswordReset = async (event) => {
        event.preventDefault()
        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords don't match");
            setNewPassword('');
            setConfirmPassword('');
            return;
        }

        if (newPassword.length < 6) {
            setErrorMessage("Password must be at least 8 characters long");
            setNewPassword('');
            setConfirmPassword('');
            return;
        }

        setIsLoading(true);

        try {
            await axiosInstance.post('/password/reset/confirm/', {
                "uid": uid, "token": token, "new_password1": newPassword, "new_password2": confirmPassword
            });

            navigate("/login");
        } catch (error) {
            console.error("Password reset failed:", error);
            setErrorMessage("Failed to reset password. Please try again");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!uid || !token) {
            setErrorMessage("Please recheck the link");
        }
    }, [uid, token, errorMessage]);

    if (!!isLoading) { return <div className={styles.loading}>Loading task...</div> }


    return (
        <div className={styles.forgotPasswordContainer}>
            <div className={styles.forgotPasswordBox}>
                <div className={styles.logoContainer}>
                </div>
                <h2 className={styles.title}>Create New Password</h2>
                <form onSubmit={handlePasswordReset} className={styles.form}>
                    <div>
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            className={styles.input}
                        />
                    </div>
                    <div>
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className={styles.input}
                        />
                    </div>
                    <button
                        type="submit"
                        className={styles.setButton}>
                        {isLoading ? "Setting..." : "Set New Password"}
                    </button>
                    {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordPage;
