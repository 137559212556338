import React, {useEffect, useState} from 'react';
import axiosInstance from "../../../axiosConfig";
import styles from './AttachmentsForm.module.css';

const AttachmentForm = ({ taskId }) => {
    const [file, setFile] = useState(null);
    const [link, setLink] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleLinkChange = (e) => {
        setLink(e.target.value);
    };

    const handleAttachmentSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formData = new FormData();

        if (file) {
            formData.append('file', file);
        }

        if (link) {
            formData.append('link', link);
        }

        try {
            const response = await axiosInstance.post(`/attachment/tasks/${taskId}/attachments/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setFile(null);
            setLink('');
            getAttachments();

        } catch (error) {
            console.error('Error creating attachment:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const getAttachments = async (e) => {
        try {
            const response = await axiosInstance.get(`/attachment/tasks/${taskId}/`)
            setAttachments(response);

        } catch (error) {
            console.error('Error get attachment:', error);
        }
    };

    useEffect(() => {
        if (taskId) { getAttachments() }
    }, [taskId]);

    return (
        <div>
            <form onSubmit={handleAttachmentSubmit} className={styles.attachmentForm}>
                <div className={styles.attachmentFormField}>
                    <label>Attach a file:</label>
                    <input type="file" onChange={handleFileChange} />
                </div>
                <div className={styles.attachmentFormField}>
                    <label>Or Attach a link:</label>
                    <input type="url" value={link} onChange={handleLinkChange} placeholder="Enter link" />
                </div>
                <button type="submit" disabled={isSubmitting} className={styles.attachmentFormButton}>
                    {isSubmitting ? 'Saving...' : 'Save Attachment'}
                </button>
            </form>

            <div className={styles.attachmentsList}>
                {attachments.length === 0 ? (
                    <p className={styles.noAttachmentsText}>No attachments yet</p>
                ) : (
                    <div className={styles.attachmentListContainer}>
                        {attachments.map((attachment) => (
                            <div key={attachment.id} className={styles.attachmentItem}>
                                {attachment.file ? (
                                    <div className={styles.attachmentCard}>
                                        <i className={styles.attachmentIcon}></i>
                                        <div className={styles.attachmentDetails}>
                                            <a href={attachment.file} className={styles.attachmentLink}>
                                                {attachment.file?.split('/').pop()}
                                            </a>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.attachmentCard}>
                                        <div className={styles.attachmentDetails}>
                                            <a href={attachment.link} className={styles.attachmentLink}>
                                                {attachment.link}
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AttachmentForm;
