import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosConfig";
import { TASK_STATUSES } from '../../api';
import filterIcon from '../../assets/filter.svg';
import styles from './TaskListPage.module.css';


const TaskList = () => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [filterStatus, setFilterStatus] = useState("All");
    const [filterDate, setFilterDate] = useState("All");
    const [activeTaskId, setActiveTaskId] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isTaskOptionsVisible, setIsTaskOptionsVisible] = useState(false);
    const navigate = useNavigate();

    const fetchTasks = async () => {
        try {
            const response = await axiosInstance.get("/task/tasks/");
            const sortedTasks = response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setTasks(sortedTasks);
        } catch (error) {
            console.error("Error fetching tasks:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTasks();
    }, [navigate]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const deleteTask = async (taskId) => {
        try {
            await axiosInstance.delete(`/task/${taskId}/`);
            setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
            setIsTaskOptionsVisible(false);
            setSuccessMessage("Task deleted successfully");
        } catch (error) {
            if (error.response && error.response.status === 403) {
                // Show a user-friendly error message
                setErrorMessage("You cannot delete this task. You do not have the necessary permissions");
                setTimeout(() => setErrorMessage(""), 3000);
            } else {
                console.error("Error deleting task:", error);
                setErrorMessage("Error while trying to delete the task.. Please try again later");
                setTimeout(() => setErrorMessage(""), 3000);
            }
        }
    };

    const handleTaskAction = (action, taskId) => {
        if (action === "details") {
            navigate(`/task/${taskId}`);
        } else if (action === "delete") {
            deleteTask(taskId);
            setTimeout(() => setSuccessMessage(""), 4000);
        }
    };

    const filteredTasks = tasks.filter((task) => {
        const matchesSearch = task.title.toLowerCase().includes(searchQuery);
        const matchesStatus =
            filterStatus === "All" || task.status.toLowerCase() === filterStatus.toLowerCase();
        const matchesDate =
            filterDate === "All" ||
            (filterDate === "Newest" && new Date(task.created_at) >= new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)) ||
            (filterDate === "Oldest" && new Date(task.created_at) <= new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
        return matchesSearch && matchesStatus && matchesDate;
    });

    const handleTaskClick = (taskId) => {
        navigate(`/task/${taskId}`);
    };

    if (!!loading) { return <div className={styles.loading}>Loading tasks...</div> }

    return (
        <div className={styles.taskListMain}>
            {successMessage && (<div className={styles.toast}>{successMessage} </div>)}
            {errorMessage && (<div className={styles.toast}>{errorMessage} </div>)}
            <div className={styles.taskListHeader}>
                <h2>Active issues</h2>
                <div className={styles.searchFilterContainer}>
                    <div className={styles.searchWrapper}>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search tickets..."
                            className={styles.searchInput}
                        />
                    </div>
                    <div className={styles.filterButtonWrapper}>
                        <div className={styles.filterButton}>
                            <img src={filterIcon} alt="Filter" className={styles.filterIcon}/>
                        </div>
                        <div className={styles.filterDropdown}>
                            <div className={styles.filterOption}>
                                Filter by status
                                <div className={styles.nestedDropdown}>
                                    <div className={styles.nestedOption} onClick={() => setFilterStatus('All')}>ALL
                                    </div>
                                    {TASK_STATUSES.map((status) => (
                                        <div
                                            key={ status }
                                            className={ styles.nestedOption }
                                            onClick={() => setFilterStatus(status)}>
                                            { status }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.filterOption}>
                                Filter by created date
                                <div className={styles.nestedDropdown}>
                                    <div className={styles.nestedOption} onClick={() => setFilterDate('All')}>ALL</div>
                                    <div className={styles.nestedOption}
                                         onClick={() => setFilterDate("Newest")}>NEWEST
                                    </div>
                                    <div className={styles.nestedOption}
                                         onClick={() => setFilterDate("Oldest")}>OLDEST
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.taskListContainer}>
                <div className={styles.taskListHeaderRow}>
                    <div className={styles.taskColumn}></div>
                    <div className={styles.taskColumn}>Task</div>
                    <div className={styles.titleColumn}>Title</div>
                    <div className={styles.statusColumn}>Status</div>
                    <div className={styles.createdAtColumn}>Created At</div>
                </div>
                {filteredTasks.length === 0 ? (
                    <div className={styles.noResults}>
                        Oops.. No tasks found
                    </div>
                ) : (
                    filteredTasks.map((task) => (
                        <div
                            key={task.id}
                            className={`${styles.taskListItem} ${
                                activeTaskId === task.id ? styles.activeTask : ""
                            }`}
                            onClick={() => handleTaskClick(task.id)}
                        >
                            <div className={styles.actionButtonWrapper}>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActiveTaskId(task.id);
                                    }}
                                    className={styles.actionButton}>
                                    <span style={{fontWeight: "bold"}}>i</span>
                                </button>
                            </div>
                            <div className={styles.taskColumn}>{task.id}</div>
                            <div className={styles.titleColumn}>{task.title}</div>
                            <div className={styles.statusColumn}>{task.status}</div>
                            <div className={styles.createdAtColumn}>{new Date(task.created_at).toLocaleDateString()}</div>
                        </div>
                    ))
                )}
            </div>
            {activeTaskId && (
                <div className={styles.modalOverlay} onClick={() => setActiveTaskId(null)}>
                    <div
                        className={styles.optionsModal}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h3 className={styles.taskOptionsTitle}>Select Your Action:</h3>
                        <button
                            className={styles.optionButton}
                            onClick={() => handleTaskAction("details", activeTaskId)}>
                            Go to Details
                        </button>
                        <button
                            className={styles.optionButton}
                            onClick={() => {
                                setActiveTaskId(null);
                                setTimeout(() => handleTaskAction("delete", activeTaskId), 300);
                            }}>
                            Delete Task
                        </button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default TaskList;
