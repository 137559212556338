import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from "../../axiosConfig";
import styles from './UserDetailPage.module.css';
import defaultAvatar from '../../assets/default-avatar.png';

const UserDetailPage = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);
    const [avatarFile, setAvatarFile] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');


    const fetchUser = async () => {
        try {
            const response = await axiosInstance.get(`/user/current`);
            setUser(response);
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (!!file) { setAvatarFile(file) }
    };

    const handlePasswordChange = () => {
        setIsModalOpen(true);
    };

    const handlePasswordReset = async () => {
        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords don't match");
            setNewPassword('');
            setConfirmPassword('');
            return;
        }
        try {
            const response = await axiosInstance.post('password/change',
                { "new_password1": newPassword, "new_password2": confirmPassword });

            if (response.detail === "New password has been saved.") {
                setNewPassword('');
                setConfirmPassword('');
                setIsModalOpen(false);
                setErrorMessage('');
                setSuccessMessage("Password has been changed successfully");
                setTimeout(() => setSuccessMessage(''), 4000)
            } else {
                setErrorMessage("Something went wrong. Please try again");
                setNewPassword('');
                setConfirmPassword('');
            }

        } catch (error) {
            if (error.response.status === 400 && error.response.data) {
                const errors = error.response.data;

                if (errors.new_password2) {
                    setErrorMessage(errors.new_password2.join(" "));
                } else {
                    setErrorMessage("An unknown error occurred");
                }

            setNewPassword('');
            setConfirmPassword('');

            } else {
                console.error('Error resetting password:', error);
                setErrorMessage("An error occurred while resetting the password");
                setNewPassword('');
                setConfirmPassword('');
            }
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains(styles.modalOverlay)) {
            setIsModalOpen(false);
        }
    };
    const handleSave = async () => {
        try {
            const formData = new FormData();
            formData.append("first_name", user?.first_name);
            formData.append("last_name", user?.last_name);
            formData.append("email", user?.username);
            if (!!avatarFile) { formData.append("avatar", avatarFile) }

            const response = await axiosInstance.patch(`/user/current`, formData);
            setUser(response);
            setIsUpdated(true);
            setSuccessMessage("User data saved successfully");
            setTimeout(() => setSuccessMessage(''), 4000)
        } catch (error) {
            console.error("Error saving user data:", error);
        }
    };

    if (!!loading) { return <div className={styles.loading}>Loading user...</div> }

    return (
        <div className={styles.container}>
            {successMessage && (<div className={styles.toast}>{successMessage} </div>)}
            <div className={styles.card}>
                <div className={styles.cardBody}>
                <div className={styles.avatarSection}>

                    <div className={styles.avatarWrapper}>
                            <img
                                src={user?.avatar || defaultAvatar}
                                alt="User Avatar"
                                className={styles.userAvatar}
                            />
                            <label htmlFor="avatarUpload" className={styles.editButton}>
                                Edit
                            </label>
                            <input
                                type="file"
                                id="avatarUpload"
                                name="avatar"
                                accept="image/*"
                                className={styles.fileInput}
                                onChange={handleAvatarChange}
                            />
                        </div>
                        <h2 className={styles.userTitle}>
                            User Details
                        </h2>
                    </div>

                    <div className={styles.contactInfo}>
                        <label className={styles.label}>Email:</label>
                        <input
                            type="email"
                            name="username"
                            value={user?.username || ""}
                            onChange={handleChange}
                            className={styles.inputField}
                        />
                        <label className={styles.label}>First name:</label>
                        <input
                            type="text"
                            name="first_name"
                            value={user?.first_name || ""}
                            onChange={handleChange}
                            className={styles.inputField}
                        />
                        <label className={styles.label}>Last name:</label>
                        <input
                            type="text"
                            name="last_name"
                            value={user?.last_name || ""}
                            onChange={handleChange}
                            className={styles.inputField}
                        />
                        <label className={styles.label}>
                            <span
                                onClick={handlePasswordChange}
                                className={styles.clickableLink}>Change password
                            </span>
                        </label>
                        {isModalOpen && (
                            <div className={styles.modalOverlay}
                                 onClick={handleOverlayClick} >
                                <div className={styles.optionsModal}>
                                    <h2 className={styles.taskOptionsTitle}>Change password</h2>
                                    <input
                                        type="password"
                                        placeholder="New password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className={styles.inputField}
                                    />
                                    <input
                                        type="password"
                                        placeholder="Confirm password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className={styles.inputField}
                                    />
                                    {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                                    <button onClick={handlePasswordReset} className={styles.optionButton}>
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.actionsSection}>
                        <button onClick={handleSave} className={styles.saveButton}>
                        Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserDetailPage;
