import React, { useState } from "react";
import Image from "../../assets/image.png";
import Logo from "../../assets/logo.png";
import GoogleSvg from "../../assets/icons8-google.svg";
// import { GoogleLogin } from "@react-oauth/google";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import styles from "./LoginPage.module.css";
import {getSessionId, getUserToken,} from "../../api";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const location = useLocation();

    const handleLogin = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (email && password) {
            try {
                const response = await getUserToken({ "username": email, password });
                console.log("response:", response);
                toast.success("Login successful!");
                const from = location.state?.from?.pathname || '/tasklist';
                navigate(from, { replace: true });
            } catch (error) {
                setErrorMessage("Invalid email or password. Please try again");
            }
        } else {
            toast.error("Please fill all inputs");
        }
    };

    // const handleGoogleLoginSuccess = (response) => {
    //     console.log("Google login response:", response);
    //     // Here, send the response.credential (ID token) to your backend for validation and authentication.
    //     toast.success("Google Login successful!");
    //     navigate('/tasklist');
    // };
    //
    // // Google login error callback
    // const handleGoogleLoginFailure = (error) => {
    //     console.error("Google login failed:", error);
    //     toast.error("Google login failed. Please try again.");
    // };


    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginBox}>
                <div className={styles.logoContainer}>
                    <img src={Logo} alt="Quantum logo" className={styles.logo} />
                </div>
                <h2 className={styles.title}>Welcome</h2>
                <p className={styles.subtitle}>Log in to your account</p>
                <form onSubmit={handleLogin} className={styles.form}>
                    <div className={styles.inputGroup}>
                        <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className={styles.input}
                        />
                        <button
                            type="button"
                            className={styles.togglePassword}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                    <button type="submit" className={styles.primaryButton}>
                        Log In
                    </button>
                </form>

                <div className={styles.googleLoginContainer}>
                    {/*<GoogleLogin*/}
                    {/*    onSuccess={handleGoogleLoginSuccess}*/}
                    {/*    onError={handleGoogleLoginFailure}*/}
                    {/*    theme="outline"*/}
                    {/*    text="signin_with"*/}
                    {/*    size="large"*/}
                    {/*    logo_alignment="left"*/}
                    {/*/>*/}
                </div>

                <div className={styles.forgotPasswordContainer}>
                    <Link to="/forgot-password" className={styles.forgotPasswordLink}>
                        Forgot password?
                    </Link>
                </div>
                {errorMessage && <div className={styles.error}>{errorMessage}</div>}
            </div>
        </div>
    );
};

export default Login;
