import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-links">
                    <Link to="/task/create" className="navbar-item">Create Task</Link>
                    <Link to="/tasklist" className="navbar-item">Task List</Link>
                    <Link to="/userdetail" className="navbar-item">My profile</Link>
                    <Link to="/logout" className="navbar-item">Logout</Link>
                </div>
                <div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
