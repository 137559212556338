import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from "../../axiosConfig";
import {TASK_STATUSES} from "../../api";
import styles from './TaskDetailPage.module.css';
import { fetchDestinations } from '../../api';


const TaskCreatePage = () => {
    const [task, setTask] = useState({
        status: "TO DO", deadline: null,
        deadline_type: "SOFT", title: "",
        description: "", type: 1,
        destination: [], reference: "",
        structure: "", text: "", user: []
    });
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [destinations, setDestinations] = useState([]);
    const [changes, setChanges] = useState({});
    const [users, setUsers] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTask((prevTask) => ({ ...prevTask, [name]: name === "destination" ? [value] : value }));
    };

    const getDestinations = async () => {
        const response = await fetchDestinations();
        setDestinations(response);
    };

    const handleSubmit = async () => {
        try {
            const response = await axiosInstance.post('/task/', task);
            setToastMessage("Task created successfully!  Going to detail page...");
            setShowToast(true);

            setTimeout(() => { navigate(`/task/${response.id}/`) }, 3000);
        } catch (error) {
            console.error("Error creating task:", error);
            setError("Failed to create task, please recheck your input");
        }
    };

    useEffect(() => {
        getDestinations();
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axiosInstance.get('/user/users/');
                setUsers(response);
            } catch (error) { console.error("Error fetching users:", error) }
        };
        fetchUsers();
        }, []);

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.cardBody}>
                    <h3 className={styles.taskTitle}>Create New Task</h3>

                    {error && <div className={styles.error}>{error}</div>}

                    <div>
                        <div className={styles.field}>
                            <label htmlFor="users" className={styles.label}>Assign Users:</label>
                            <select
                                id="users"
                                name="users"
                                value={task?.user || []}
                                onChange={(e) => {
                                    const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                                    setTask((prevTask) => ({ ...prevTask, user: selectedOptions }));
                                    setChanges((prevChanges) => ({ ...prevChanges, user: selectedOptions }));
                                }}
                                multiple
                                className={styles.inputField}>
                                { users.map((user) => (
                                    <option key={ user.id } value={ user.id }>
                                        { user.first_name && user.last_name ? `${ user.first_name } ${ user.last_name }` : user.username }
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.inlineFields}>
                        <div className={styles.field}>
                            <label className={styles.label}>Status:</label>
                            <select
                                name="status"
                                value={task.status}
                                onChange={handleInputChange}
                                className={styles.inputField}>
                                {TASK_STATUSES.map((status) => (
                                    <option key={ status } value={ status }>
                                        { status }
                                    </option>
                                ))}
                                </select>
                            </div>
                            <div className={styles.field}>
                                <label className={styles.label}>Deadline:</label>
                                <input
                                    id="deadline"
                                    type="date"
                                    name="deadline"
                                    value={task?.deadline || null}
                                    onChange={handleInputChange}
                                    className={styles.inputField}/>
                            </div>
                            <div className={styles.field}>
                                <label className={styles.label}>Deadline Type:</label>
                                <select
                                    name="deadline_type"
                                    value={task.deadline_type}
                                    onChange={handleInputChange}
                                    className={styles.inputField}>
                                    <option value="SOFT">Soft</option>
                                    <option value="HARD">Hard</option>
                                </select>
                            </div>
                        </div>
                        <label className={styles.label}>Title:</label>
                        <input
                            type="text"
                            name="title"
                            value={task.title}
                            onChange={handleInputChange}
                            className={styles.inputField}/>
                        <label className={styles.label}>Description:</label>
                        <textarea
                            name="description"
                            value={task.description}
                            onChange={handleInputChange}
                            className={styles.textArea}/>
                        <label className={styles.label}>Type:</label>
                        <select
                            name="type"
                            value={task.type}
                            onChange={handleInputChange}
                            className={styles.inputField}>
                            <option value={1}>Inner</option>
                            <option value={2}>Outer</option>
                        </select>
                        <div className={styles.inlineFields}>
                            <div className={styles.field}>
                                <label htmlFor="destination" className={styles.label}>Destination:</label>
                                <select
                                    id="destination"
                                    name="destination"
                                    value={task?.destination || []}
                                    onChange={(e) => {
                                        const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
                                        setTask((prevTask) => ({ ...prevTask, destination: selectedOptions }));
                                        setChanges((prevChanges) => ({ ...prevChanges, destination: selectedOptions }));
                                    }}
                                    multiple
                                    className={styles.inputField}>
                                    {destinations.map((destination) => (
                                        <option key={ destination.id } value={ destination.id }>{ destination.name }</option>
                                    ))}
                                </select>
                                <div className={styles.tagsContainer}>
                                    {task?.destination?.map((id) => { const destination = destinations.find(d => d.id === id);
                                        return (
                                            destination && (
                                                <div key={id} className={styles.tag}>
                                                    {destination.name}
                                                    <button
                                                        onClick={() => {
                                                            const updatedDestinations = task.destination.filter(destId => destId !== id);
                                                            setTask((prevTask) => ({ ...prevTask, destination: updatedDestinations }));
                                                            setChanges((prevChanges) => ({ ...prevChanges, destination: updatedDestinations }));
                                                        }}
                                                        className={styles.removeTagButton}>
                                                    </button>
                                                </div>
                                            )
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={styles.field}>
                                <label className={styles.label}>Reference:</label>
                                <input
                                    type="text"
                                    name="reference"
                                    value={task.reference}
                                    onChange={handleInputChange}
                                    className={styles.inputField}/>
                            </div>
                        </div>
                        <label className={styles.label}>Structure:</label>
                        <textarea
                            name="structure"
                            value={task.structure}
                            onChange={handleInputChange}
                            className={styles.textArea}/>
                        <label className={styles.label}>Text:</label>
                        <textarea
                            name="text"
                            value={task.text}
                            onChange={handleInputChange}
                            className={styles.textArea}/>
                    </div>
                    <div className={styles.actionsSection}>
                        <button onClick={handleSubmit} className={styles.submitButton}>Create task</button>
                    </div>
                </div>
            </div>
            { showToast && (<div className={styles.toast}>{ toastMessage }</div>) }
        </div>
    );
};

export default TaskCreatePage;
