import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosConfig";

const clearCookies = () => {
    const cookies = document.cookie.split(";");

    cookies.forEach((cookie) => {
        const cookieName = cookie.split("=")[0].trim();
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
};

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const logoutUser = async () => {
            try {
                await axiosInstance.get('/logout');
                clearCookies();
                navigate("/login");
            } catch (error) {
                console.error("Error logging out:", error);
            }
        };

        logoutUser();
    }, [navigate]);
};

export default Logout;
